@import "@styles/layouts/template";

@import "@styles/components/button";

.page-error {
    height: calc(100vh);
    padding-top: 130px;
    padding-bottom: 100px;
    background-image: url("/images/404/bg-4x.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include tablet {
        padding-top: 230px;
    }

    @include tablet-min {
        padding-top: 102px;
        background-image: url("/images/404/bg-4x-768.jpg");;
    }

    &__main {
        margin-bottom: 40px;
        display: flex;
        align-items: flex-end;
        column-gap: 18px;

        @include laptop {
            margin-bottom: 32px;
        }

        @include tablet {
            margin-bottom: 24px;
            column-gap: 14px;
        }

        @include tablet-min {
            margin-bottom: 20px;
        }
    }

    &__num {
        margin: 0;
        font-size: 164px;
        font-weight: 600;
        line-height: 100%;
        color: $color-nightly-aurora;

        @include laptop {
            font-size: 96px;
        }

        @include tablet-min {
            font-size: 64px;
        }
    }

    &__descr {
        display: flex;
        flex-direction: column;
        row-gap: 14px;
        padding-bottom: 14px;

        @include laptop {
            row-gap: 12px;
            padding-bottom: 5px;
        }

        @include tablet {
            row-gap: 4px;
        }

        p {
            margin: 0;
            color: $color-nightly-aurora;

            @extend .p2;
        }
    }

    &__go-to {
        width: 100%;
        max-width: 360px;
        text-align: center;

        @include laptop {
            max-width: 300px;
        }

        @include tablet {
            max-width: 220px;
        }

        @include tablet-min {
            max-width: 188px;
        }
    }
}
